import React, { useEffect, useState } from 'react'
import CareerPathConst from '../utils/CareerPath.const';

export const CareerPath = () => {
    const [careers, setCareers] = useState([])

    const getAllCareers = async () => {
        try {
            setCareers(CareerPathConst)
        } catch (error) { }
    }

    useEffect(() => {
        getAllCareers()
    }, [])

    return (
        <section className='career-path' id='careerpath'>
            <div className="container">
                <div className="section-title">
                    <h3>Career Path</h3>
                    <p>Opportunities don't happen, you create them.</p>
                </div>

                <div className="career-path-content">
                    <div className="row g-2">
                        {careers.map((career) => (
                            <div className="col-xl-3 col-lg-3 col-md-4 col-12" key={career._id}>
                                <div className="card shadow h-100 career-path-content-card">
                                    <div className="card-body">
                                        <div className={`career-path-content-icon ${career.className}`}>
                                            <i className={career.icon}></i>
                                        </div>
                                        <div className="career-path-content-title">
                                            <small className='text-muted fw-500'>{career.type}</small>
                                            <a href={career.ref_link}>
                                                <h4 className='career-title'>{career.title}</h4>
                                            </a>
                                        </div>
                                        <div className="career-path-content-description">
                                            <p>{career.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}
