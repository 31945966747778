import React, { useState } from "react";
import news_letter from "../assets/img/news_letter.png";
import no_image from "../assets/img/no_image.png";
import { subscribeNewsletter } from "../services/services";
import toast from "react-hot-toast";

export const Newsletters = () => {
  const [email, setEmail] = useState("");
  const validateEmail = (e) => {
    var email_value = e.target.value;
    setEmail(email_value);
  };

  const onImageError = (e) => {
    e.target.src = no_image;
    e.target.style = "padding: 15px;";
  };

  const submitNewsletter = async (e) => {
    e.preventDefault();
    if (email && Object.keys(email).length > 5) {
      const res = await subscribeNewsletter(email);
      if (res?.status === 201) {
        toast.success(res?.message, {
          className: "react-hot-toast",
        });
        setEmail("");
      } else {
        toast.error(res?.message, {
          className: "react-hot-toast",
        });
      }
    }
  };

  return (
    <section className="news-letter" id="news-letter">
      <div className="container">
        <div className="row g-2">
          <div className="col-xl-6 col-lg-6 col-md-12 col-12">
            <div className="news-letter-image">
              <img src={news_letter} alt="news letter" onError={onImageError} />
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-12 d-flex flex-column justify-content-center">
            <div className="py-3">
              <div className="section-title text-center">
                <h3>Subscribe to Our Newsletter</h3>
                <p>You will never miss our latest news</p>
              </div>
              <div className="news-letter-content">
                <form onSubmit={submitNewsletter} className="mb-3">
                  <div className="news-letter-content-text">
                    <i className="fa-solid fa-paper-plane me-2"></i>
                    <span>With our newsletter you can stay updated</span>
                  </div>
                  <div className="news-letter-content-input input-group hero-input-group">
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      placeholder="Email address"
                      name="subscribed_input"
                      id="subscribed_input"
                      value={email}
                      onChange={(e) => validateEmail(e)}
                    />
                    <button
                      type="submit"
                      className="btn btn-primary"
                      name="subscribed_button"
                      id="subscribed_button"
                    >
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
