export const requestInterceptor = async (request) => {
    try {
        const token = localStorage.getItem('accessToken') || "";
        if (token) {
            request.headers['Authorization'] = `Bearer ${token}`;
        }
        return request
    } catch (error) {
        return request
    }
}

export const responseInterceptor = (response) => {
    try {
        return response
    } catch (error) {
        return response
    }
}