import { Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import HomePage from "./pages/HomePage";
import PrivateRoutes from "./utils/PrivateRoutes";
import PublicRoutes from "./utils/PublicRoutes";
import { SignIn } from "./pages/SignIn";
import { SignUp } from "./pages/SignUp";
import { Provider } from "react-redux";
import store from "./utils/store/store";
import { Course } from "./pages/Course";

const HomePageComponent = Layout(HomePage);
const SignInComponent = Layout(SignIn);
const SignUpComponent = Layout(SignUp);
const CourseComponent = Layout(Course);

function App() {
  return (
    <Provider store={store}>
      <Routes>
        <Route path='/' element={<HomePageComponent />}></Route>
        <Route path='/course/:code' element={<CourseComponent />}></Route>
        <Route element={<PrivateRoutes />}>

        </Route>

        <Route element={<PublicRoutes />}>
          <Route path='/sign-in' element={<SignInComponent />}></Route>
          <Route path='/sign-up' element={<SignUpComponent />}></Route>
        </Route>

        <Route path='*' element={<HomePageComponent />}></Route>
      </Routes>
    </Provider>
  );
}

export default App;
