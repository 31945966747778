import React, { useEffect, useState } from 'react';
import { Autoplay, Pagination } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from 'swiper/react';
import no_image from '../assets/img/no_image.png';
import { getTestimonials } from '../services/services';

export const Testimonials = () => {
    const [testimonialData, setTestimonialData] = useState([])

    const getTestimonialsList = async () => {
        try {
            const res = await getTestimonials()
            if (res?.data?.count) {
                const resData = res?.data?.result?.map((testimony) => {
                    const ratings = []
                    for (let i = 0; i < 5; i++) {
                        if (i <= testimony['rating'] - 1) {
                            ratings.push('fa-solid fa-star text-warning');
                        } else {
                            ratings.push('fa-regular fa-star');
                        }
                    }
                    testimony['rating'] = ratings
                    return testimony
                })
                setTestimonialData(resData)
            }
        } catch (error) {}
    }

    const onImageError = (e) => {
        e.target.src = no_image
        e.target.style = 'padding: 15px;'
    }

    useEffect(() => {
        getTestimonialsList()
    }, [])

    return (
        <section className='testimonials'>
            <div className="container">

                <div className="section-title">
                    <h3>Our Testimonials </h3>
                    <p>Honest feedback from our learners around the globe.</p>
                </div>

                <div className='testimonials-content'>
                    <Swiper
                        spaceBetween={5}
                        autoplay={{ delay: 3500, disableOnInteraction: false, }}
                        loop={true}
                        loopFillGroupWithBlank={true}
                        breakpoints={{ 640: { slidesPerView: 1 }, 768: { slidesPerView: 2 }, 1024: { slidesPerView: 3 }, }}
                        modules={[Autoplay, Pagination]}
                        className="mySwiper">

                        {testimonialData?.map((test, index) => (
                            <SwiperSlide key={index} className='p-2'>
                                <div className="card testimonials-card shadow">
                                    <div className="card-body">
                                        <div className="testimonial-message">
                                            <p className='m-0 fw-500'><i className="fa-solid fa-quote-left me-1"></i><small>{test.testimonial}</small><i className="fa-solid fa-quote-right ms-1"></i></p>
                                        </div>
                                    </div>
                                    <div className="card-footer testimonial-author">
                                        <div className="row g-2">
                                            <div className="col">
                                                <div className="testimonial-author-text">
                                                    <h6><i className="fa-solid fa-user-tie me-2"></i>{test.name}</h6>
                                                    <div>
                                                        {test.rating.map((rate, ind) => (
                                                            <i className={rate} key={ind}></i>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="testimonial-author-image border">
                                                    <img src={test.image} alt="" onError={onImageError} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    )
}
