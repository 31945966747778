import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import headerLogo from "../assets/img/logo.png";
import { getIpAddress } from "../services/services";
import { API_SETTINGS } from "../settings/settings";
import { getIp } from "../utils/store/slices/ipAddressSlice";

export const Header = () => {
  const dispatch = useDispatch();
  const ipAddress = useSelector((state) => state?.ipAddress?.ip);
  const [mobileNav, setMobileNav] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [activeMenu, setActiveMenu] = useState("sign-up");
  const location = useLocation();
  const accessToken = localStorage.getItem("accessToken");
  const [currentUser, setCurrentUser] = useState({});

  const signOut = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const res = await axios.post(API_SETTINGS.SIGN_OUT, { refreshToken });
      localStorage.clear();
      toast.success(res.data.message, { className: "react-hot-toast" });
      setIsLogin(false);
    } catch {
      localStorage.clear();
      toast.success("Logout Success!", { className: "react-hot-toast" });
      setIsLogin(false);
    }
  };

  useEffect(() => {
    const currentUrl = location.pathname.split("/")[1];
    if (currentUrl) {
      setActiveMenu(currentUrl);
    } else {
      setActiveMenu(activeMenu);
    }
    const bgIncludes = ["sign-in", "sign-up"];
    if (bgIncludes.includes(currentUrl)) {
      document.body.classList.add("body-bg");
    } else {
      document.body.classList.remove("body-bg");
    }
  }, [location, activeMenu]);

  useEffect(() => {
    setIsLogin(true);
    const getCurrentUser = async () => {
      try {
        if (accessToken) {
          const res = await axios.get(API_SETTINGS.CURRENT_USER);
          if (res?.data?.status === 200 && res?.data?.message === "Success") {
            setCurrentUser(res.data.data);
            setIsLogin(true);
          }
        } else {
          localStorage.clear();
          setIsLogin(false);
        }
      } catch (error) {
        localStorage.clear();
      }
    };
    getCurrentUser();
  }, [accessToken]);

  useEffect(() => {
    const getUserIP = async () => {
      if (!ipAddress) {
        const ip = await getIpAddress();
        // console.log(await ipify({useIPv6: false}));
        if (ip) {
          dispatch(getIp(ip));
        }
      }
    };
    getUserIP();
  }, [ipAddress, dispatch]);

  return (
    <header id="header" className="web-header shadow-cu">
      <div className="container">
        <Link to="/" className="web-header-logo d-flex align-items-center">
          <div className="web-header-logo-image">
            <img src={headerLogo} alt="" />
          </div>
          <h3 className="web-header-logo-title">Signo Venture</h3>
        </Link>
        <i
          className="fa-solid fa-bars mobile-nav-menu"
          onClick={() => setMobileNav(true)}
        ></i>
        <nav id="navbar" className={mobileNav ? "navbar mobile-nav" : "navbar"}>
          <i
            className="fa-solid fa-xmark mobile-nav-close"
            onClick={() => setMobileNav(false)}
          ></i>
          <ul>
            <li>
              <Link
                className={
                  activeMenu === "support" ? "nav-link active" : "nav-link"
                }
                href="tel:+917200094576"
                onClick={() => setMobileNav(false)}
              >
                <i className="fa-solid fa-headset"></i>Support
              </Link>
            </li>
            {isLogin && (
              <li className="dropdown">
                <Link className="nav-link profile">
                  <div className="profile-image">
                    <img
                      src={
                        currentUser?.image ||
                        "https://ik.imagekit.io/rawager/avatars/avatar01.png"
                      }
                      alt=""
                    />
                  </div>
                  <span className="profile-title">
                    {currentUser.first_name
                      ? currentUser.first_name
                      : "Hi! Welcome"}
                  </span>
                  <i className="fa-solid fa-chevron-down"></i>
                </Link>
                <ul>
                  <li>
                    <Link to="/dashboard">
                      <i className="fa-solid fa-server"></i>Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/dashboard">
                      <i className="fa-solid fa-user-shield"></i>Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="" onClick={signOut}>
                      <i className="fa-solid fa-right-from-bracket"></i>Sign Out
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {!isLogin && (
              <>
                <li>
                  <Link
                    className={
                      activeMenu === "sign-in" ? "nav-link active" : "nav-link"
                    }
                    to="/sign-in"
                    onClick={() => setMobileNav(false)}
                  >
                    <i className="fa-solid fa-right-to-bracket"></i>Sign In
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      activeMenu === "sign-up" ? "nav-link active" : "nav-link"
                    }
                    to="/sign-up"
                    onClick={() => setMobileNav(false)}
                  >
                    <i className="fa-regular fa-file-lines"></i> Sign Up{" "}
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
};
