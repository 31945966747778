import React from 'react'
import { AboutUs } from '../components/AboutUs'
import { Footer } from '../components/Footer'
import { CareerPath } from '../components/CareerPath'
import { Companies } from '../components/Companies'
import { Counter } from '../components/Counter'
import { Courses } from '../components/Courses'
import { DemoSection } from '../components/DemoSection'
import { HeroSection } from '../components/HeroSection'
import { Newsletters } from '../components/Newsletters'
import { Placements } from '../components/Placements'
import { Testimonials } from '../components/Testimonials'
import { YourSchedule } from '../components/YourSchedule'

const HomePage = () => {
    return (
        <>
            <HeroSection />
            <Counter />
            <CareerPath />
            <AboutUs />
            <Courses />
            <YourSchedule />
            <Testimonials />
            <DemoSection />
            <Placements />
            <Newsletters />
            <Companies />
            <Footer />
        </>
    )
}

export default HomePage