import React, { useEffect, useState } from "react";
import sv_half_logo from "../assets/img/logo.png";
import no_image from "../assets/img/no_image.png";
import branchesConst from "../assets/static/branches.json";
import socialNetworkConst from "../assets/static/socialNetwork.json";
import {
  getBranches,
  getSocialNetworks,
  getWebViews,
} from "../services/services";

export const Footer = () => {
  const [viewCount, setViewCount] = useState(0);
  const [socialNetwork, setSocialNetwork] = useState(socialNetworkConst);
  const [branches, setBranches] = useState(branchesConst);
  const [year, setYear] = useState(new Date().getFullYear());

  const getBranchDetails = async () => {
    const res = await getBranches();
    if (res?.data?.count) {
      setBranches(res.data.result);
    }
  };

  const getViewsDetails = async () => {
    const res = await getWebViews();
    if (res?.data) {
      const count = String(res?.data).padStart(4, "0");
      setViewCount(count);
    }
  };

  const getSocialDetails = async () => {
    const res = await getSocialNetworks();
    if (res?.data?.count) {
      setSocialNetwork(res.data.result);
    }
  };

  const onImageError = (e) => {
    e.target.src = no_image;
    e.target.style = "padding: 15px;";
  };

  useEffect(() => {
    getBranchDetails();
    getViewsDetails();
    getSocialDetails();
    setYear(new Date().getFullYear());
  }, []);

  return (
    <section className="footer">
      <footer>
        <div className="container">
          <div className="footer-top">
            <div className="footer-top-image">
              <img src={sv_half_logo} alt="logo" onError={onImageError} />
            </div>

            <div className="footer-top-contact my-3">
              <div className="row g-2">
                {branches?.map((branch, index) => (
                  <div
                    className="col-xl-4 col-lg-4 col-md-6 col-12 p-2"
                    key={index}
                  >
                    <h6 className="text-info m-0">{branch.title}</h6>
                    <small className="">{branch.address}</small>
                    <div className="contacts mt-2">
                      <a
                        href={`https://wa.me/91${branch.whatsapp}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-dark mx-2"
                      >
                        <i className="fa-brands fa-whatsapp me-2 text-muted"></i>
                        <span>+91&nbsp;{branch.whatsapp}</span>
                      </a>
                      <a
                        href={`tel:+91${branch.mobile}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-dark mx-2"
                      >
                        <i className="fa-solid fa-mobile-screen-button me-2 text-muted"></i>
                        <span>+91&nbsp;{branch.mobile}</span>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="footer-top-details">
              <h6>
                <a
                  href="mailto:signoventure@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-solid fa-envelope me-2 text-info"></i>
                </a>
                signoventure@gmail.com
              </h6>
            </div>
          </div>
          <div className="footer-bottom">
            <hr />
            <div className="row g-2">
              <div className="col-xl-6 col-lg-6 col-md-6 col-12 fw-500 text-center text-md-start">
                <span>Copyright &copy;</span>
                <span className="mx-1">{year}</span>
                <span className="mx-2">|</span>
                <span>All Rights Reserved</span>
                <span className="mx-2">|</span>
                <span>
                  Visits : <span className="">{viewCount}</span>
                </span>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-12 text-center text-md-end">
                <ul className="footer-bottom-social-links m-0 p-0">
                  {socialNetwork?.map((social, index) => (
                    <li key={index}>
                      <a href={social.link} target={social.target} className="">
                        <i className={social.icon}></i>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
};
