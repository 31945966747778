import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import no_image from '../assets/img/no_image.png';
import { getCategory, getCategoryCourses } from '../services/services';

export const Courses = () => {
    const [categories, setCategories] = useState([])
    const [isDomain, setIsDomain] = useState("")
    const [courses, setCourses] = useState([])

    const getCategoryList = async () => {
        const res = await getCategory();
        if (res?.data?.result?.length) {
            setCategories(res.data.result)
            console.log("Category", [...res.data.result].shift());
            getCoursesList([...res.data.result].shift())
        }
    }

    const getCoursesList = async (domain) => {
        setIsDomain(domain.title)
        const res = await getCategoryCourses(domain.title)
        if (res?.data?.count) {
            const courses = res?.data?.result?.map((course) => {
                const ratings = []
                for (let i = 0; i < 5; i++) {
                    if (i <= course['rating'] - 1) {
                        ratings.push('fa-solid fa-star text-warning');
                    } else {
                        ratings.push('fa-regular fa-star');
                    }
                }
                course['rating'] = ratings
                return course
            })
            setCourses(courses)
        } else {
            setCourses([])
        }
    }

    useEffect(() => {
        getCategoryList()
    }, [])


    const onImageError = (e) => {
        e.target.src = no_image
        e.target.style = 'padding: 15px;'
    }

    return (
        <section className='our-courses' id='our-courses'>
            <div className="container">
                <div className="section-title">
                    <h3>Our Courses</h3>
                    <p>A broad selection of courses. Find your favorite one</p>
                </div>
                <div className="our-courses-domains">
                    {categories.map((category) => (
                        <button type='button' className={`btn btn btn-light m-1 ${category.title === isDomain ? 'active' : ''}`} key={category.code} onClick={() => getCoursesList(category)}>{category.title}</button>
                    ))}
                </div>
                <div className="our-courses-courses">
                    {courses.length > 0 &&
                        <div className="row g-2">
                            {courses.map((course) => (
                                <div className="col-xl-3 col-lg-3 col-md-4 col-12" key={course._id}>
                                    <div className="card shadow border-0 overflow-hidden">
                                        <div className="our-courses-courses-image border-bottom">
                                            <img src={course.image} alt={course.title} onError={onImageError} />
                                        </div>
                                        <div className="card-body">
                                            <div className="our-courses-courses-type mb-2">
                                                <small className='text-muted'>{course.domain} Course</small>
                                                <span className="me-2">
                                                    {course?.rating?.map((rate, ind) => (
                                                        <i className={rate} key={ind}></i>
                                                    ))}
                                                </span>
                                            </div>
                                            <div className="our-courses-courses-title">
                                                <Link to={'course/' + course.code}>
                                                    <h6 >{course.title}</h6>
                                                </Link>
                                            </div>
                                            <small className='our-courses-courses-duration text-muted'>{course.duration} Duration</small>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="col-12 text-center">
                                <div className="py-4">
                                    <Link to={`courses?domain=${isDomain}`}>
                                        <h6 className='m-2 text-primary'>Explore More Courses<i className="fa-solid fa-angles-right ms-2"></i></h6>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </section>
    )
}
