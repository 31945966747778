const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    ip: null
}
const ipAddressSlice = createSlice({
    name: "ipAddress",
    initialState,
    reducers: {
        getIp(state, action) {
            state.ip = action.payload
        }
    }
})

export const { getIp } = ipAddressSlice.actions
export default ipAddressSlice.reducer