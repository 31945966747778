import React from 'react'
import your_schedule from '../assets/img/your_schedule.jpg'
import no_image from '../assets/img/no_image.png'

export const YourSchedule = () => {

    const onImageError = (e) => {
        e.target.src = no_image
        e.target.style = 'padding: 15px;'
    }

    return (
        <section id='' className='your-schedule'>
            <div className='container'>
                <div className="row g-2">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="section-title">
                            <h3>Customize With Your Schedule</h3>
                            <p>Professional Online Tutor on Your Schedule, Personalized</p>
                        </div>
                        <div className="your-schedule-description">
                            <p>Thanks to the being available of our tutors during plenty of time slots which are specifically created to suit each student's unique learning style, our scheduling method enables careful consideration of both time and student compatibility.</p>
                            <p>You can spend more time doing the things you want because your schedule isn't controlled by classes. Additionally, since you don't have to commute, you can save time.</p>
                            <p>Set alerts in a calendar and use it. Making a schedule will not only make studying easier, but it will also make it easier for you to make time for other aspects of your life.</p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="your-schedule-image">
                            <img src={your_schedule} alt="news letter" onError={onImageError} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
