import * as moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import no_image from '../assets/img/no_image.png';
import { getCourse, getModuleVideos } from '../services/services';

export const Course = () => {
    const { code } = useParams()
    const [course, setCourse] = useState({})
    const [courseModule, setCourseModule] = useState({})

    const getCourseDetails = async (course) => {
        const res = await getCourse(course)
        if (res?.data?._id) {
            const courseDetails = res?.data
            const ratings = []
            for (let i = 0; i < 5; i++) {
                if (i <= courseDetails['rating'] - 1) {
                    ratings.push('fa-solid fa-star text-warning me-1');
                } else {
                    ratings.push('fa-regular fa-star text-dark me-1');
                }
            }
            courseDetails['ratings'] = ratings
            setCourse(courseDetails)
        }
    }

    const getModuleDetails = async (code) => {
        if (!courseModule[code]) {
            const res = await getModuleVideos(code)
            if (res?.data?.length) {
                setCourseModule((prev) => {
                    return {
                        ...prev,
                        [code]: res.data
                    }
                })
            }
        }
    }

    const onImageError = (e) => {
        e.target.src = no_image
        e.target.style = 'padding: 15px;'
    }

    useEffect(() => {
        getCourseDetails(code)
    }, [code])

    return (
        <section className='course mt-3'>
            <div className='container'>
                <div className="row g-2">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="course-image">
                            <img src={course?.image} alt={course?.title} onError={onImageError} />
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12 py-2 px-3">
                        <h4 className='fw-bold'>{course?.title}</h4>
                        <p className='fw-500 text-justify'>{course?.description}</p>
                        <h6 className='text-muted fw-600'>Rating :
                            <span className='mx-2 text-dark'>{course?.rating}</span>
                            <span className="me-2">
                                {course?.ratings?.map((rate, ind) => (
                                    <i className={rate} key={ind}></i>
                                ))}
                            </span>
                        </h6>
                        <h6 className='fw-600'><span className='text-muted fw-500 me-2'>Created by</span>{course?.author}</h6>
                        <h6 className='fw-600'><span className='text-muted fw-500 me-2'>Last updated</span>{moment(course?.updated_at).format("MMMM YYYY")}</h6>
                        <h6 className='fw-600'><span className='text-muted fw-500 me-2'>Language</span>{course?.language}</h6>
                    </div>
                    <div className="col-12">
                        <div className="border p-3 rounded">
                            <h5 className='fw-600'>What you'll learn</h5>
                            <div className="row g-2 mt-2">
                                {course?.learn?.map((learn, ind) => (
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-12" key={ind}>
                                        <small className='fw-500'><i className="fa-solid fa-check me-2"></i>{learn}</small>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="p-3">
                            <h5 className='fw-600'>This course includes</h5>
                            <div className="row g-2 mt-2">
                                {course?.includes?.map((include, ind) => (
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-12" key={ind}>
                                        <div className="course-includes">
                                            <h6 className='fw-500'><i className={include?.icon}></i>{include.title}</h6>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-12">
                        <div className="p-3">
                            <h5 className='fw-600'>Course content</h5>
                            <div className="accordion" id="accordionExample">
                                {course?.modules?.map((module, ind) => (
                                    <div className="accordion-item my-2 border rounded overflow-hidden" key={ind}>
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-600 text-dark" type="button" data-bs-toggle="collapse" data-bs-target={`#course${ind}`} aria-expanded="true" aria-controls="collapseOne" onClick={() => getModuleDetails(module?.code)}>
                                                {module?.title}
                                            </button>
                                        </h2>
                                        <div id={`course${ind}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                {courseModule?.[module.code]?.map((video, videoInd) => (
                                                    <div key={videoInd}>
                                                        <div className="row">
                                                            <div className="col-auto"><i className="fa-solid fa-video text-danger"></i></div>
                                                            <div className="col"><h6>{video?.title}</h6></div>
                                                            <div className="col-auto"><small className='text-success fw-500'>Video</small></div>
                                                        </div>

                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
