import axios from 'axios';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import loginImg from '../assets/img/left_login_img.png';
import { API_SETTINGS } from '../settings/settings';
import no_image from "../assets/img/no_image.png";

export const SignUp = () => {
    const navigate = useNavigate()
    const [signUp, setSignUp] = useState({
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
    })

    const formValidation = (event) => {
        const fieldName = event?.target?.name
        const fieldValue = event?.target?.value
        if (fieldName === "mobile") {
            const re = /^[0-9\b]+$/;
            if (fieldValue !== '' && !re.test(fieldValue)) {
                return false
            }
        }
        setSignUp({
            ...signUp,
            [fieldName]: fieldValue
        })
    }

    const formSubmission = async (event) => {
        try {

            event.preventDefault();
            if (signUp.first_name.length < 5 || signUp.last_name.length < 3 || signUp.email.length < 5 || signUp.mobile.length !== 10) {
                return
            }
            const res = await axios.post(API_SETTINGS.SIGN_UP, signUp);
            if (res?.data?.status === 201 && res?.data?.message === "Created Successfully!") {
                toast.success(res.data.message, { className: "react-hot-toast" })
                navigate(`/sign-in?mobile=${signUp.mobile}`)
            }
        } catch (error) {
            if (error?.response?.data?.statusCode === 404 && String(error?.response?.data?.message).includes("Cannot")) {
                toast.error("Unable to process your request!", { className: "react-hot-toast" })
            } else {
                toast.error(error?.response?.data?.message, { className: "react-hot-toast" })
            }
        }
    }

    const onImageError = (e) => {
        e.target.src = no_image;
        e.target.style = "padding: 15px;";
    };

    return (
        <section className="sign-up">
            <div className="container">
                <div className="card">
                    <div className="card-body">
                        <div className="row g-2">
                            <div className="col-lg-7 col-xl-7 col-md-7 col-12 d-flex justify-content-center align-items-center flex-column">
                                <div className="sign-up-image">
                                    <img src={loginImg} alt="Sign Up" onError={onImageError} />
                                </div>
                                <div className="text-center mt-4 mb-3 mb-md-0">
                                    <h6 className='fw-500'>You already have an account? <Link to={'/sign-in'} className='py-1 px-2 bg-warning text-dark rounded'>Sign In</Link></h6>
                                </div>
                            </div>
                            <div className="col-lg-5 col-xl-5 col-md-5 col-12">
                                <div className="card-form">
                                    <h3 className="card-title">Signo Venture</h3>
                                    <div className="card-quote">
                                        "Education is the most powerful weapon which you can use to change the world."
                                    </div>
                                    <form action="" method="post" onSubmit={(event) => formSubmission(event)}>
                                        <div className="row g-2">
                                            <div className="col-6">
                                                <div className="">
                                                    <label htmlFor="first_name" className="form-label">First Name<span>*</span></label>
                                                    <input type="text" className="form-control" id="first_name" name="first_name" placeholder='First name' value={signUp.first_name} onChange={formValidation} />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="">
                                                    <label htmlFor="last_name" className="form-label">Last Name<span>*</span></label>
                                                    <input type="text" className="form-control" id="last_name" name="last_name" placeholder='Last name' value={signUp.last_name} onChange={formValidation} />
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                <div className="">
                                                    <label htmlFor="email" className="form-label">Email<span>*</span></label>
                                                    <input type="email" className="form-control" id="email" name="email" placeholder='Email address' value={signUp.email} onChange={formValidation} />
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                <div className="">
                                                    <label htmlFor="mobile" className="form-label">Mobile<span>*</span></label>
                                                    <input type="tel" className="form-control" id="mobile" name="mobile" placeholder='Mobile' maxLength="10" value={signUp.mobile} onChange={formValidation} />
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                <div className="my-2 text-end">
                                                    <button type="submit" name='submit_button' id="submit_button" className="btn btn-success fw-500"><i className="fa-regular fa-circle-check me-2"></i>Sign Up</button>
                                                </div>
                                            </div>
                                            <div className="col-12">

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
