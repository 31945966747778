import { useEffect, useState } from "react";
import counterConst from "../assets/static/counter.json";
import { getCounters } from "../services/services";

export const Counter = () => {
  const [counters, setCounters] = useState(counterConst);

  const getCounter = async () => {
    try {
      const res = await getCounters();
      if (res?.status === 200 && res?.data?.length) {
        setCounters(res.data);
      }
    } catch {}
  };

  useEffect(() => {
    getCounter();
  }, []);

  return (
    <section id="" className="number-section">
      <div className="container">
        <div className="row g-2">
          {counters?.map((count) => (
            <div className="col-xl-3 col-lg-3 col-md-3 col-6" key={count._id}>
              <div className="number-section-card">
                <div className="number-section-card-body border-start border-end">
                  <h3>{count.max}</h3>
                  <h4>{count.title}</h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
