const env = process.env;

export const API_SETTINGS = {
    LOGIN: `${env.REACT_APP_BASE_URL}/login`,
    VERIFY_OTP: `${env.REACT_APP_BASE_URL}/verify`,
    USER_API: `${env.REACT_APP_BASE_URL}/user`,
    SIGN_UP: `${env.REACT_APP_BASE_URL}/sign-up`,
    SIGN_IN: `${env.REACT_APP_BASE_URL}/sign-in`,
    SIGN_OUT: `${env.REACT_APP_BASE_URL}/sign-out`,
    CURRENT_USER: `${env.REACT_APP_BASE_URL}/me`,
    CATEGORY: `${env.REACT_APP_BASE_URL}/category`,
    COURSES: `${env.REACT_APP_BASE_URL}/course`,
    COUNTER: `${env.REACT_APP_BASE_URL}/counter`,
    COMPANY: `${env.REACT_APP_BASE_URL}/company`,
    TESTIMONIALS: `${env.REACT_APP_BASE_URL}/testimonial`,
    VIEWS: `${env.REACT_APP_BASE_URL}/view`,
    SOCIAL_NETWORK: `${env.REACT_APP_BASE_URL}/social-network`,
    ADDRESS: `${env.REACT_APP_BASE_URL}/address`,
    BRANCH: `${env.REACT_APP_BASE_URL}/branch`,
    PLACEMENT: `${env.REACT_APP_BASE_URL}/placement`,
    NEWSLETTER: `${env.REACT_APP_BASE_URL}/newsletter`,
    IP_ADDRESS: `${env.REACT_APP_IP_ADDRESS}`,
};