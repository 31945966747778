import React, { useEffect, useState } from "react";
import no_image from "../assets/img/no_image.png";
import companiesConst from "../assets/static/companies.json";
import { getCompanies } from "../services/services";

export const Companies = () => {
  const [companies, setCompanies] = useState(companiesConst);

  const getCompaniesDetails = async () => {
    const res = await getCompanies();
    if (res?.data?.count) {
      setCompanies(res.data.result);
    }
  };

  const onImageError = (e) => {
    e.target.src = no_image;
    e.target.style = "padding: 15px;";
  };

  useEffect(() => {
    getCompaniesDetails();
  }, []);
  return (
    <section id="companies" className="companies">
      <div className="container">
        <div className="section-title">
          <h3>companies</h3>
          <p>Learning these skills are highly in demand by Companies</p>
        </div>
        <div className="row g-3">
          {companies.map((company) => (
            <div className="col-xl-3 col-lg-3 col-md-3 col-6" key={company._id}>
              <a href={company.website} target="_blank" rel="noreferrer">
                <div className="card companies-card shadow-sm">
                  <div className="card-body">
                    <div className="companies-card-image">
                      <img src={company.image} alt="" onError={onImageError} />
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
