import React from 'react'
import { useState } from 'react';

export const TopBar = () => {
    const [isTopBar, setIsTopBar] = useState(true)
    const closeTopBar = () => {
        setIsTopBar(false)
    }
    return (
        <>
            {isTopBar &&
                <section className='top-bar'>
                    <div className='top-bar-text container'>Learning these skill prepares you for the present and the future.<b>Begin your journey with us.</b></div>
                    <div className="top-bar-close" onClick={() => closeTopBar()}><i className="fa-solid fa-xmark"></i></div>
                </section>
            }
        </>
    )
}
