import React from 'react';

export const HeroSection = () => {
    const scrollToSession = () => {
        const sessionElement = document.getElementById(`demo-section`);
        if (sessionElement) {
            sessionElement.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <section className='hero-section' id='hero-section'>
            <div className="container py-3">
                <div className="row g-5">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                        <div className="hero-section-content">
                            <h6 className='hero-section-tag'>100% Satisfaction Guarantee</h6>
                            <div className="hero-section-title">
                                <h1>Get Quality<span>Tutoring</span> <br /> Through Our<span>Leading</span> <br /> Industry<span>Experts.</span></h1>
                            </div>
                            <div className="hero-section-description">
                                <p>Experience top-notch tutoring from our exceptional team of industry experts. We understand the value of learning from professionals who have hands-on experience in their respective fields. That's why we have gathered a group of outstanding industry experts to provide you with quality tutoring and guidance.</p>
                            </div>
                            <div className="demo-button">
                                <button className="btn btn-success" onClick={() => scrollToSession()}>Request a Demo<i className="fa-solid fa-arrow-right ms-2"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                        <div className="row g-2">
                            <div className="col-6">
                                <div className="hero-image image-1">
                                    <img src="https://ik.imagekit.io/signoventure/hero-section/001.jpg" alt="" srcSet="" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="hero-image image-2">
                                    <img src="https://ik.imagekit.io/signoventure/hero-section/002.jpg" alt="" srcSet="" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="hero-image image-3">
                                    <img src="https://ik.imagekit.io/signoventure/hero-section/003.jpg" alt="" srcSet="" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="hero-image image-4">
                                    <img src="https://ik.imagekit.io/signoventure/hero-section/004.jpg" alt="" srcSet="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
