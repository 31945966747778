import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import headerLogo from '../assets/img/logo.png';
import { API_SETTINGS } from '../settings/settings';

export const SignIn = () => {
    const navigate = useNavigate()
    const [isOTPSubmit, setIsOTPSubmit] = useState(false)
    const [mobileLoader, setMobileLoader] = useState(false)
    const [otpLoader, setOtpLoader] = useState(false)
    const location = useLocation().search;
    const mobile = new URLSearchParams(location).get("mobile");
    const [signInForm, setSignInForm] = useState({
        mobile: mobile ? mobile : "",
        otp: "",
    })

    const MobileHandler = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setSignInForm({
                mobile: event.target.value,
                otp: ""
            });
        }
    }

    const OtpHandler = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setSignInForm({
                ...signInForm,
                otp: event.target.value,
            });
        }
    }

    const loginSubmit = async () => {
        try {
            setMobileLoader(true)
            const config = {
                method: 'POST',
                url: API_SETTINGS.SIGN_IN,
                data: { mobile: signInForm['mobile'] }
            };
            const resData = await axios(config)
            if (resData && resData?.data?.status === 201) {
                setIsOTPSubmit(true)
                toast.success(resData?.data?.message, { className: "react-hot-toast" })
                setIsOTPSubmit(true)
                setSignInForm({
                    mobile: signInForm.mobile,
                    otp: ""
                });
                setMobileLoader(false)
            } else {
                setMobileLoader(false)
                toast.error(resData?.data?.message, { className: "react-hot-toast" })
            }
        } catch (error) {
            setMobileLoader(false)
        }
    }

    const verifyOtp = async () => {
        try {
            setOtpLoader(true)
            const config = {
                method: 'POST',
                url: API_SETTINGS.VERIFY_OTP,
                data: signInForm
            };
            const resData = await axios(config)
            if (resData && resData?.data?.status === 201) {
                toast.success(resData?.data?.message, { className: "react-hot-toast" })
                localStorage.setItem("accessToken", resData?.data?.data?.accessToken)
                localStorage.setItem("refreshToken", resData?.data?.data?.refreshToken)
                setOtpLoader(false)
                navigate("/")
            } else {
                setOtpLoader(false)
                toast.error(resData?.data?.message, { className: "react-hot-toast" })
            }
        } catch (error) {
            setOtpLoader(false)
            toast.error(error?.response?.data?.message, { className: "react-hot-toast" })
        }
    }

    const formSubmission = async (event) => {
        event.preventDefault();
        if (isOTPSubmit) {
            if (signInForm.mobile && signInForm.otp && signInForm.otp.length === 6) {
                await verifyOtp()
            } else {
                toast.error("Enter your OTP!", { className: "react-hot-toast" })
            }
        } else {
            await loginSubmit()
        }
    }
    return (
        <section className="sign-in">
            <div className="container">
                <div className="card">
                    <div className="card-body">
                        <div className="my-2">
                            <div className="card-logo">
                                <img src={headerLogo} alt="" />
                            </div>
                            <h2 className="card-title">Signo Venture</h2>
                        </div>
                        <div className="card-quote">
                            "Education is the most powerful weapon which you can use to change the world. to instil in a young engineer the confidence they need to succeed at the highest level possible in the technical field and in the relevant domain."
                        </div>
                        <div className="card-form">
                            <form action="" method="post" className='mb-4' onSubmit={(event) => formSubmission(event)}>
                                <div className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between p-1">
                                        <label className="form-label text-muted" htmlFor="sign-in-mobile">Mobile No :</label>
                                        {isOTPSubmit &&
                                            <small className="text-end text-secondary cursor-pointer fw-500" onClick={() => setIsOTPSubmit(false)}><i className="fa-solid fa-caret-left me-1"></i>Edit</small>
                                        }
                                    </div>
                                    <input type="tel" className={isOTPSubmit ? 'form-control border-primary bg-white fw-500 cursor-no-drop' : 'form-control bg-white fw-500'} id="sign-in-mobile" placeholder="Enter mobile number" autoComplete='off' maxLength={10} value={signInForm.mobile} onChange={MobileHandler} disabled={isOTPSubmit} />
                                </div>
                                {isOTPSubmit &&
                                    <div className="mb-3">
                                        <label className="form-label text-muted" htmlFor="sign-in-mobile">OTP :</label>
                                        <input type="tel" className="form-control fw-500" name='otpNumber' id="otpNumber" placeholder="Enter your OTP" value={signInForm.otp} maxLength="6" onChange={OtpHandler} required />
                                    </div>
                                }
                                <div className="mb-3">
                                    {!isOTPSubmit && !mobileLoader &&
                                        <button type="submit" name='otp-button' className="btn btn-success w-100"><i className="fa-solid fa-paper-plane me-2"></i>Send OTP</button>
                                    }
                                    {!isOTPSubmit && mobileLoader &&
                                        <button type="submit" className="btn btn-success fw-500 w-100" disabled>
                                            <div className="spinner-border text-light spinner-border-sm me-2" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                            Sending OTP
                                        </button>
                                    }
                                    {isOTPSubmit && !otpLoader &&
                                        <button type="submit" name='verify-button' className="btn btn-success w-100"><i className="fa-solid fa-right-to-bracket me-2"></i>Sign In</button>
                                    }
                                    {isOTPSubmit && otpLoader &&
                                        <button type="submit" className="btn btn-success fw-500 w-100" disabled>
                                            <div className="spinner-border text-light spinner-border-sm me-2" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                            Sending OTP
                                        </button>
                                    }
                                </div>
                            </form>
                            <div className="text-center">
                                <h6 className='fw-500'>Don't have an account? <Link to={'/sign-up'} className='ms-1'>Sign Up</Link></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
