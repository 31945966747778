import React from 'react'

export const AboutUs = () => {
    return (
        <section className='about-us' id=''>
            <div className="container">
                <div className="section-title">
                    <h3>About Us</h3>
                    <p>Accelerate your growth. by joining with us</p>
                </div>
                <div className="row g-2">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="about-us-image">
                            <div className="about-us-image-one shadow">
                                <img src="https://ik.imagekit.io/signoventure/about-us/about-us-one.jpg" alt="" srcSet="" />
                            </div>
                            <div className="about-us-image-two shadow">
                                <img src="https://ik.imagekit.io/signoventure/about-us/about-us-two.jpg" alt="" srcSet="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className="about-us-content">
                            <h2 className='about-us-content-title my-3'>Talented and Highly Qualified<span>Tutors</span></h2>
                            <p>Carefully designed for “freshers & working professionals”. Get rich content at an affordable price, Outstanding Industry expert support!</p>
                            <p>The <b>Signoventure</b> team comprises of talented and passionate design engineers who has the thought of sharing the knowledge and showing the path leading to the jobs in core sector.</p>
                            <p>This <b>Signoventure</b> doesn't like to think of its instructors as tutors — it calls them mentors. The service comes up with a personalized workplan for each student, records all of its sessions for review, and also holds regular check-ins with parents. </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
