const CareerPathConst = [
    {
        "_id": "634a64e7b4f776bd8b25b61a",
        "type": "Step 1",
        "className": "first",
        "title": "Enroll With Us",
        "description": "Enroll with us in 10 weeks program",
        "icon": "fa-solid fa-file-signature",
        "is_active": true,
        "ref_link": "#freesection"
    },
    {
        "_id": "634a64e8b4f776bd8b25b61c",
        "type": "Step 2",
        "title": "Learn",
        "className": "second",
        "description": "Learn from the industry experts in the live className and get the support at anytime.",
        "icon": "fa-solid fa-book-open",
        "is_active": true,
        "ref_link": "#courses"
    },
    {
        "_id": "634a64e9b4f776bd8b25b61e",
        "type": "Step 3",
        "title": "Finish",
        "className": "third",
        "description": "Make yourself stronger by working on the real time projects for 2 weeks and get ready to crack the interview.",
        "icon": "fa-solid fa-circle-check",
        "is_active": true,
        "ref_link": "#placements"
    },
    {
        "_id": "634a64eab4f776bd8b25b620",
        "type": "Step 4",
        "title": "Get Hired",
        "className": "fourth",
        "description": "Complete the course, pass the assessments & get the interviews!",
        "icon": "fa-solid fa-briefcase",
        "is_active": true,
        "ref_link": "#companie"
    }
]

module.exports = CareerPathConst