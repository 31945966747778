import React, { useEffect, useState } from "react";
import { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import no_image from "../assets/img/no_image.png";
import { getPlacements } from "../services/services";

export const Placements = () => {
  const [placements, setPlacements] = useState([]);

  const getPlacementDetails = async () => {
    const res = await getPlacements();
    if (res?.data?.count) {
      setPlacements(res.data.result);
    }
  };

  const onImageError = (e) => {
    e.target.src = no_image;
    e.target.style = "padding: 15px;";
  };

  useEffect(() => {
    getPlacementDetails();
  }, []);

  return (
    <section className="placements">
      <div className="container">
        <div className="section-title">
          <h3>Recent Placements</h3>
          <p>Signoventure supports you to get placed in top companies</p>
        </div>

        <div className="placements-content">
          <Swiper
            spaceBetween={5}
            autoplay={{ delay: 3500, disableOnInteraction: false }}
            loop={true}
            loopFillGroupWithBlank={true}
            breakpoints={{
              640: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
            {placements.map((record, index) => (
              <SwiperSlide key={index} className="p-2">
                <div className="placements-card card shadow border-0">
                  <div className="card-body">
                    <div className="row g-2">
                      <div className="col-auto">
                        <div className="placements-card-image border">
                          <img
                            src={record.image}
                            alt={record.name}
                            onError={onImageError}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="placements-card-title">
                          <h6>{record.name}</h6>
                          <p>
                            <i className="fa-solid fa-briefcase"></i>
                            {record.role}
                          </p>
                          <p>
                            <i className="fa-solid fa-building"></i>
                            {record.company}
                          </p>
                        </div>
                      </div>
                      <div className="col-12">
                        <hr className="my-1" />
                        <div className="placements-card-content">
                          <h6 className="placements-card-content-title">
                            <span>Course :</span>
                            {record.course.title}
                          </h6>
                          <p className="placements-card-content-description">
                            {record.course.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};
