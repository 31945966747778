import axios from 'axios'
import validator from 'validator'
import toast from 'react-hot-toast'
import React, { useState } from 'react'
import { API_SETTINGS } from '../settings/settings'
import freeSectionImage from '../assets/img/free_section.png'
import no_image from '../assets/img/no_image.png'

export const DemoSection = () => {
    const [isMessage, setIsMessage] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")

    function handleChange(event) {
        switch (event.target.name) {
            case 'demo_name':
                setName(event.target.value)
                break;
            case 'demo_email':
                validateEmail(event)
                break;
            case 'demo_mobile':
                isMobile(event)
                break;
            default:
                break;
        }
    }

    const isMobile = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setMobile(event.target.value)
        }
    }

    const validateEmail = (e) => {
        var email_value = e.target.value
        setEmail(email_value)
        if (validator.isEmail(email_value)) {
            setEmailError(false)
        } else {
            setEmailError(true)
        }
    }

    const createDemoSection = async (e) => {
        e.preventDefault();
        if (name && Object.keys(name).length > 3 && email && Object.keys(email).length > 5 && mobile && Object.keys(mobile).length === 10) {
            try {
                const body = {
                    name: name,
                    email: email,
                    mobile: mobile
                }
                const res = await axios.post(API_SETTINGS.CREATE_FREE_SECTION, body, { headers: { "Content-Type": "application/json", }, },);
                toast.success(res.data.message, { className: "react-hot-toast" })
                if (res.status === 201) {
                    setIsMessage(true)
                    setName("")
                    setEmail("")
                    setMobile("")
                }
            } catch (e) {
                toast.error('Oops! Something went wrong!', { className: "react-hot-toast" })
            }
        } else {
            toast.error('Oops! Check the details you have entered!', { className: "react-hot-toast", duration: 2000 })
        }
    }

    const onImageError = (e) => {
        e.target.src = no_image
        e.target.style = 'padding: 15px;'
    }

    return (
        <section className='demo-section' id='demo-section'>
            <div className="container">
                <div className="row g-3 mb-4">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                        <div className="section-title">
                            <h3>Free Demo Session</h3>
                            <p className='mb-0'>Just answer a few simple details. so we can contact you as soon as possible</p>
                        </div>
                        <hr />
                        <div className="demo-section-form">
                            {!isMessage &&
                                <form action="" method="post" onSubmit={createDemoSection}>
                                    <div className="row g-3">
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                                            <div className="form-group">
                                                <label htmlFor="demo_email" className='fw-500 mb-1'>Full Name<span className='text-danger'>*</span></label>
                                                <input className="form-control form-control" type="text" placeholder="full name" name='demo_name' id='demo_name' value={name} onChange={handleChange} autoComplete='off' />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                                            <div className="form-group">
                                                <label htmlFor="demo_email" className='fw-500 mb-1'>Mobile Number <span className='text-danger'>*</span></label>
                                                <input className="form-control form-control" type="text" inputMode='numeric' pattern="[0-9]*" maxLength="10" placeholder="xxxxx xxxxx" name='demo_mobile' id='demo_mobile' value={mobile} onChange={handleChange} autoComplete='off' />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="demo_email" className='fw-500 mb-1'>Email Address <span className='text-danger'>*</span></label>
                                                <input className="form-control form-control" type="email" placeholder="example@gmail.com" name='demo_email' id='demo_email' value={email} onChange={handleChange} autoComplete='off' />
                                                {emailError &&
                                                    <small className='text-danger fw-500'>Invalid email address</small>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                            <div className="form-group text-end">
                                                <button type='submit' className='btn btn-info fw-500 ms-2 mb-5'><i className="fa-solid fa-video me-2"></i>Live demo</button>
                                                <button type='submit' className='btn btn-warning fw-500 ms-2 mb-5'><i className="fa-solid fa-display me-2"></i>Watch demo</button>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            }
                            {isMessage &&
                                <div className="d-flex flex-column align-items-center justify-content-center h-100">
                                    <i className="fa-solid fa-circle-check text-success fa-3x"></i>
                                    <div className="mt-3 text-center">
                                        <h5 className='web-primary-color'>We received your request will be in touch shortly</h5>
                                        <h6 className='fw-500 text-danger'>Thanks for Choosing us</h6>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-12 d-flex align-items-center justify-content-center">
                        <div className="demo-section-image">
                            <img src={freeSectionImage} alt="" onError={onImageError} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
