import React from 'react'
import { Header } from './components/Header'
import { TopBar } from './components/TopBar'

const Layout = (Component) => ({ ...Props }) => {
    return (
        <>
            <TopBar />
            <Header />
            <Component {...Props} />{ }
        </>
    )
}

export default Layout