import axios from "axios";
import { API_SETTINGS } from "../settings/settings";

export const getIpAddress = async () => {
    try {
        const ipAddress = await axios.get(API_SETTINGS.IP_ADDRESS);
        if (ipAddress?.data) {
            const res = await axios.post(API_SETTINGS.VIEWS, { type: "view", ip_address: String(ipAddress.data).replace(/\n/g, ''), web: true });
            return res?.data
        }
        return String(ipAddress?.data)?.replace(/\n/g, '') || null
    } catch { }
}

export const getCounters = async () => {
    try {
        const res = await axios.get(API_SETTINGS.COUNTER);
        return res?.data
    } catch { }
}

export const getCategory = async () => {
    try {
        const res = await axios.get(`${API_SETTINGS.CATEGORY}`);
        return res?.data
    } catch (error) {
        return error?.response?.data || null
    }
}

export const getCategoryCourses = async (domain) => {
    try {
        const res = await axios.get(`${API_SETTINGS.COURSES}?order=asc&limit=4&domain=${domain}`);
        return res?.data
    } catch (error) {
        return error?.response?.data || null
    }
}

export const getCourse = async (code) => {
    try {
        const res = await axios.get(`${API_SETTINGS.COURSES}/${code}`);
        return res?.data
    } catch (error) {
        return error?.response?.data || null
    }
}

export const getModuleVideos = async (module) => {
    try {
        const res = await axios.get(`${API_SETTINGS.COURSES}/module/${module}`);
        return res?.data
    } catch (error) {
        return error?.response?.data || null
    }
}

export const getTestimonials = async () => {
    try {
        const res = await axios.get(`${API_SETTINGS.TESTIMONIALS}`);
        return res?.data
    } catch (error) {
        return error?.response?.data || null
    }
}

export const getWebViews = async () => {
    try {
        const res = await axios.get(`${API_SETTINGS.VIEWS}/count`);
        return res?.data
    } catch (error) {
        return error?.response?.data || null
    }
}

export const getSocialNetworks = async () => {
    try {
        const res = await axios.get(`${API_SETTINGS.SOCIAL_NETWORK}`);
        return res?.data
    } catch (error) {
        return error?.response?.data || null
    }
}

export const getAddress = async () => {
    try {
        const res = await axios.get(`${API_SETTINGS.ADDRESS}`);
        return res?.data
    } catch (error) {
        return error?.response?.data || null
    }
}

export const getBranches = async () => {
    try {
        const res = await axios.get(`${API_SETTINGS.BRANCH}`);
        return res?.data
    } catch (error) {
        return error?.response?.data || null
    }
}

export const getPlacements = async () => {
    try {
        const res = await axios.get(`${API_SETTINGS.PLACEMENT}`);
        return res?.data
    } catch (error) {
        return error?.response?.data || null
    }
}

export const getCompanies = async () => {
    try {
        const res = await axios.get(`${API_SETTINGS.COMPANY}?limit=12`);
        return res?.data
    } catch (error) {
        return error?.response?.data || null
    }
}

export const subscribeNewsletter = async (email) => {
    try {
        const res = await axios.post(`${API_SETTINGS.NEWSLETTER}`, { email });
        return res?.data
    } catch (error) {
        return error?.response?.data || null
    }
}
